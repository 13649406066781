<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix">
            <span>{{ $t('account.profile.tab-addresses') }}</span>
            <span style="float: right">
                <el-button @click="showDialog" type="primary" icon="el-icon-plus" size="mini" plain>
                    {{ $t('account.profile.addresses.insert') }}
                </el-button>
            </span>
        </div>

        <el-table
            :data="addresses"
            :empty-text="$t('account.couriers.no-accounts')"
            style="width: 100%"
            size="small"
            border
        >
            <el-table-column
                prop="id"
                :label="$t('account.profile.addresses.label-id')"
                width="100px"
            ></el-table-column>
            <el-table-column prop="address" :label="$t('account.profile.addresses.label-address')"></el-table-column>
            <el-table-column :label="$t('account.profile.addresses.label-type')">
                <template slot-scope="scope">
                    <span :class="{ 'bold-text': scope.row.type === 'hq', 'cel-color': scope.row.type === 'hq' }">
                        {{ $t('account.profile.addresses.type-' + scope.row.type) }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="county" :label="$t('account.profile.addresses.label-county')"></el-table-column>
            <el-table-column prop="city" :label="$t('account.profile.addresses.label-city')"></el-table-column>
            <el-table-column prop="zip" :label="$t('account.profile.addresses.label-zip')"></el-table-column>
            <el-table-column :label="$t('account.profile.addresses.label-favorite')">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.favorite" type="primary" size="medium"
                        ><i class="el-icon-star-on"></i
                    ></el-tag>
                </template>
            </el-table-column>
            <el-table-column label="Status" prop="status" width="90px">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'">
                        {{ scope.row.status == 1 ? $t('general.active') : $t('general.inactive') }}</el-tag
                    >
                </template>
            </el-table-column>
            <el-table-column :label="$t('account.profile.addresses.label-actions')" width="125px">
                <template slot-scope="scope">
                    <template v-if="scope.row.imported == 1">
                        <el-tooltip
                            effect="light"
                            :content="$t('account.profile.addresses.edit-disabled')"
                            placement="top"
                        >
                            <el-button type="info" size="mini" icon="el-icon-edit" plain />
                        </el-tooltip>
                    </template>
                    <template v-else>
                        <el-button
                            @click="editAddress(scope.row.id)"
                            type="primary"
                            size="mini"
                            icon="el-icon-edit"
                            plain
                        ></el-button>
                    </template>

                    <el-tooltip
                        v-if="!scope.row.favorite && scope.row.type !== 'hq' && scope.row.status"
                        effect="light"
                        :content="$t('account.profile.addresses.button-favorite')"
                        placement="top"
                    >
                        <el-button @click="setFavorite(scope.row.id)" type="primary" size="mini">
                            <i class="el-icon-star-on"></i>
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog
            :visible.sync="dialog"
            @close="clearDialog"
            :title="$t(formAddressId > 0 ? 'account.profile.addresses.edit' : 'account.profile.addresses.insert')"
            class="dialog-sm"
        >
            <el-form :model="form" :rules="formRules" ref="form" label-position="top">
                <el-form-item :label="$t('account.profile.addresses.label-type')" prop="type">
                    <el-select v-model="formType" class="fullWidth" :disabled="formType === 'hq'">
                        <el-option
                            v-if="(formAddressId && formType === 'hq') || showHQ"
                            value="hq"
                            :label="$t('account.profile.addresses.type-hq')"
                        ></el-option>
                        <el-option value="pickup" :label="$t('account.profile.addresses.type-pickup')"></el-option>
                        <el-option value="return" :label="$t('account.profile.addresses.type-return')"></el-option>
                    </el-select>
                </el-form-item>

                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-form-item :label="$t('account.profile.addresses.label-country')" prop="country_id">
                            <el-select @change="changeCountry" v-model="formCountryId" class="fullWidth" filterable>
                                <el-option
                                    v-for="country in countries"
                                    :key="country.id"
                                    :label="country.name"
                                    :value="country.id"
                                >
                                    <i :class="country.iso + ' flag'"></i>
                                    <span>{{ country.name }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <template v-if="formCountryId == 1">
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-form-item :label="$t('account.profile.addresses.label-county')" prop="county">
                                <el-select @change="changeCounty" v-model="formCounty" class="fullWidth" filterable>
                                    <el-option
                                        v-for="county in counties"
                                        :key="county"
                                        :label="county"
                                        :value="county"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('account.profile.addresses.label-city')" prop="city">
                                <el-select v-model="formCity" class="fullWidth" filterable>
                                    <el-option
                                        v-for="city in cities"
                                        :key="city.id"
                                        :value="city"
                                        :label="city"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <template v-else>
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <el-form-item :label="$t('account.profile.addresses.label-county')" prop="county">
                                <el-input v-model="formCounty"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('account.profile.addresses.label-city')" prop="city">
                                <el-input v-model="formCity"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>

                <el-form-item :label="$t('account.profile.addresses.label-address')" prop="address">
                    <el-input v-model="formAddress"></el-input>
                </el-form-item>

                <el-form-item :label="$t('account.profile.addresses.label-zip')" prop="zip">
                    <el-input v-model="formZip"></el-input>
                </el-form-item>

                <el-form-item label="Status">
                    <el-switch v-model="formStatus" :disabled="formType === 'hq' && formStatus"> </el-switch>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialog = false">{{ $t('account.profile.addresses.cancel') }}</el-button>
                <el-button @click="submitForm" type="primary">{{
                    $t(formAddressId > 0 ? 'account.profile.addresses.edit' : 'account.profile.addresses.insert')
                }}</el-button>
            </span>
        </el-dialog>
    </el-card>
</template>

<script>
import { call, sync, get } from 'vuex-pathify';

const validateZip = (rule, value, callback) => {
    if (value == null || String(value).trim() === '') {
        return callback(Error());
    }
    if (value !== '') {
        if (parseInt(value) <= 0 || value.length !== 6) {
            return callback(Error());
        }
    }
    return callback();
};

export default {
    data() {
        return {
            formRules: {
                type: [
                    {
                        required: true,
                        message: this.$t('account.profile.addresses.error-type'),
                        trigger: ['change', 'blur'],
                    },
                ],
                country_id: [
                    {
                        required: true,
                        message: this.$t('account.profile.addresses.error-country'),
                        trigger: ['change', 'blur'],
                    },
                ],
                county: [
                    {
                        required: true,
                        message: this.$t('account.profile.addresses.error-county'),
                        trigger: ['change', 'blur'],
                    },
                ],
                city: [
                    {
                        required: true,
                        message: this.$t('account.profile.addresses.error-city'),
                        trigger: ['change', 'blur'],
                    },
                ],
                address: [
                    {
                        required: true,
                        message: this.$t('account.profile.addresses.error-address'),
                        trigger: ['change', 'blur'],
                    },
                ],
                zip: [
                    {
                        required: true,
                        validator: validateZip,
                        message: this.$t('account.profile.addresses.error-zip'),
                        trigger: ['change', 'blur'],
                    },
                ],
            },
        };
    },
    computed: {
        addresses: sync('account/informations@addresses.list'),
        dialog: sync('account/informations@addresses.dialog'),
        countries: sync('account/informations@addresses.countries'),
        counties: sync('account/informations@addresses.counties'),
        cities: sync('account/informations@addresses.cities'),
        form: sync('account/informations@addresses.form'),
        ...sync('account/informations@addresses.form', {
            formAddressId: 'address_id',
            formType: 'type',
            formCountryId: 'country_id',
            formCounty: 'county',
            formCity: 'city',
            formAddress: 'address',
            formZip: 'zip',
            formStatus: 'status',
        }),
        showHQ() {
            if (!this.addresses || this.addresses.length >= 0) {
                const hqAddress = this.addresses.filter((element) => {
                    return element.type === 'hq';
                });
                if (hqAddress.length == 0) {
                    this.formType = 'hq';
                    return true;
                }
            }

            return false;
        },
    },
    methods: {
        getCountriesList: call('app/GetCountriesList'),
        getCountiesList: call('app/GetCountiesList'),
        getCitiesList: call('app/GetCitiesList'),
        getAffiliateAddresses: call('account/GetAffiliateAddresses'),
        getAffiliateAddress: call('account/GetAffiliateAddress'),
        saveAffiliateAddress: call('account/SaveAffiliateAddress'),
        setAffiliateAddressFavorite: call('account/SetAffiliateAddressFavorite'),
        showDialog() {
            this.formType = 'pickup';

            this.dialog = true;
        },
        clearDialog() {
            this.$refs.form.resetFields();
            this.cities = [];

            this.formAddressId = 0;
            this.formType = 'pickup';
            this.formCounty = '';
            this.formCity = '';
            this.formAddress = '';
            this.formZip = '';
        },
        changeCountry() {
            this.formCounty = '';
            this.changeCounty();
        },
        changeCounty() {
            this.cities = [];
            this.formCity = '';

            // Get cities list only for RO
            if (this.formCounty && this.formCountryId == 1) {
                this.getCitiesList({
                    county: this.formCounty,
                })
                    .then((res) => {
                        this.cities = res.message;
                    })
                    .catch((e) => {
                        this.$reportToSentry(e, {
                            extra: {
                                fn: 'getCitiesList',
                                params: {
                                    county: this.formCounty,
                                },
                            },
                        });
                    });
            }
        },
        editAddress(address_id) {
            this.formAddressId = address_id;

            this.getAffiliateAddress().then((res) => {
                this.formAddressId = res.message.id;
                this.formType = res.message.type;
                this.formCountryId = res.message.country_id;
                this.formCounty = res.message.county;
                this.changeCounty();

                this.formCity = res.message.city;
                this.formAddress = res.message.address;
                this.formZip = res.message.zip;

                if (res.message.status) {
                    this.formStatus = true;
                } else {
                    this.formStatus = false;
                }

                this.dialog = true;
            });
        },
        submitForm() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.saveAffiliateAddress()
                        .then((res) => {
                            if (res.message.success) {
                                if (res.message.added) {
                                    this.sbMsg({
                                        type: 'success',
                                        message: this.$t('account.profile.addresses.success-added'),
                                    });
                                } else if (res.message.updated) {
                                    this.sbMsg({
                                        type: 'success',
                                        message: this.$t('account.profile.addresses.success-updated'),
                                    });
                                }

                                this.dialog = false;
                                this.getAffiliateAddresses();
                            }
                        })
                        .catch((e) => {
                            this.$reportToSentry(e, {
                                extra: {
                                    fn: 'saveAffiliateAddress',
                                },
                            });
                        });
                }
            });
        },
        setFavorite(address_id) {
            this.setAffiliateAddressFavorite({
                id: address_id,
            })
                .then((res) => {
                    if (res.message.success) {
                        this.getAffiliateAddresses();
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'setAffiliateAddressFavorite',
                            params: {
                                id: address_id,
                            },
                        },
                    });
                });
        },
    },
    mounted() {
        this.getAffiliateAddresses().catch((e) => {
            this.$reportToSentry(e, {
                extra: {
                    fn: 'getAffiliateAddresses',
                },
            });
        });

        this.getCountriesList()
            .then((res) => {
                this.countries = res.message;
            })
            .catch((e) => {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'getCountriesList',
                    },
                });
            });

        this.getCountiesList()
            .then((res) => {
                this.counties = res.message;
            })
            .catch((e) => {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'getCountiesList',
                    },
                });
            });
    },
};
</script>
